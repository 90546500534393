<!-- index -->
<template>
  <div class="sales salesHeader-box">
    <div class="salesHeader-content">
      <div class="sales-btns">
        <el-button size="small" round icon="el-icon-plus" @click="apply">新增申请</el-button>
      </div>
      <SimpleTable
        showOperate
        showBtn1
        showBtn2
        showBtn3
        btn2Text="删除"
        showOperateWidth="180px"
        :tableData="tableData"
        :columnData="tableColumn"
        :initObj="tablePageObj"
        @onClickBtn1="handleBtn1Click"
        @onClickBtn2="handleBtn2Click"
        @onClickBtn3="handleBtn3Click"
        @onChangePageData="
          (pageObj) => {
            getMagList(pageObj)
          }
        "
      ></SimpleTable>
      <el-dialog :visible.sync="eldialogVisible" width="700px" custom-class="eldialog-info">
        <h3>{{ currentItem.magName }}</h3>
        <div v-if="showWxUrl">
          <div class="eldialog-info-item center">
            <span class="eldialog-info-item-title">微网刊当期目录：</span>
            <el-button @click="copyURL(newURL)">复制链接</el-button>
            <el-button><a :href="newURL" target="_blank">预览</a></el-button>
            <span class="code-preview">扫码预览</span>
            <div class="code" id="qrcodeNewURL"></div>
          </div>
          <div class="eldialog-info-item">
            <div class="eldialog-info-item center">
              <span class="eldialog-info-item-title">微网刊过刊目录：</span>
              <el-button @click="copyURL(oldURL)">复制链接</el-button>
              <el-button><a :href="oldURL" target="_blank">预览</a></el-button>
              <span class="code-preview">扫码预览</span>
              <div class="code" id="qrcodeOldURL"></div>
            </div>
          </div>
        </div>
        <div class="eldialog-info-item" v-if="showEmailUrl">
          <div class="eldialog-info-item">
            <span class="eldialog-info-item-title">邮件样式预览：</span>
            <div>
              <div v-for="(item, index) in currentItem.emailTemplateStyleArr" :key="index" class="eldialog-info-item-arr">
                样式{{ index + 1 }}
                <el-button @click="copyURL(item.jumpUrl)">复制链接</el-button>
                <el-button><a :href="item.jumpUrl" target="_blank">预览</a></el-button>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import SimpleTable from '@/components/SimpleTable'
import { WeChatAllJumpUrl } from '@/assets/js/util'
import { GetMagList, DeleteSaleTask } from '@/api/roleManagement'
import { GetPushTaskList, GetTemplate } from '@/api/emailPush'
import QRCode from 'qrcodejs2'
export default {
  name: 'Sales',
  data() {
    return {
      tableData: [],
      tableColumn: [
        {
          title: '期刊名称',
          prop: 'magName',
          notSortable: true
        },
        {
          title: '申请时间',
          prop: 'createTime',
          notSortable: true
        },
        {
          title: '计划开通时间',
          prop: 'planFinishTime',
          notSortable: true
        },
        {
          title: '实际开通时间',
          prop: 'finishTime',
          notSortable: true
        },
        {
          title: '状态',
          prop: 'status',
          notSortable: true
        }
      ],
      tablePageObj: {
        page: 1,
        size: 20,
        sort: 'year,issue,desc'
      },
      eldialogVisible: false,
      currentItem: {}
    }
  },
  props: {},
  components: { SimpleTable },
  computed: {
    // 微网刊当期目录链接
    newURL() {
      return WeChatAllJumpUrl(this.currentItem.magId)
    },
    oldURL() {
      let origin = window.location.origin
      return `${origin}/otherPages/Microblog/html/catalog.html?magId=${this.currentItem.magId}`
    },
    showEmailUrl() {
      return this.currentItem.node == 3 && (this.currentItem.serviceStatus == 0 || this.currentItem.serviceStatus == 1)
    },
    showWxUrl() {
      return this.currentItem.serviceStatus == 0 || this.currentItem.serviceStatus == 2
    }
  },
  watch: {},
  mounted() {
    this.getMagList()
  },
  methods: {
    // 新增申请
    apply() {
      let routeData = this.$router.resolve({
        name: 'newApply',
        query: { status: 'newApply' }
      })
      window.open(routeData.href, '_blank')
    },
    // 获取期刊列表
    getMagList(pageParams = {}) {
      let { page = 1, size = 20 } = pageParams
      let params = {
        memberId: JSON.parse(sessionStorage.getItem('user')).id,
        size,
        page: page - 1
      }
      GetMagList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          let { page = 1, size = 20 } = pageParams
          this.tablePageObj = {
            ...this.tablePageObj,
            page,
            size,
            total: data.totalElements
          }
          this.tableData = data.content
          this.tableData.map((item) => {
            // node流程节点 0:待提交 1:待设计 2:待配置 3:已完成
            if (item.node == 1) {
              item.status = '待设计'
              item.btn1Text = '删除'
              item.isHideBtn2 = true
              item.btn1Class = true
            } else if (item.node == 2) {
              item.status = '待配置'
              item.btn1Text = '删除'
              item.isHideBtn2 = true
              item.btn1Class = true
            } else if (item.node == 3) {
              item.status = '已完成'
              item.btn1Text = '预览'
              item.isHideBtn2 = true
              item.btn1Class = true
            } else {
              item.status = '待提交'
              item.btn1Text = '编辑'
              item.btn2Text = '删除'
              item.btn1Class = true
              item.btn2Class = true
            }
            if (item.serviceStatus == 1) {
              item.btn3Text = '申请微信传播服务'
            } else if (item.serviceStatus == 2) {
              item.btn3Text = '申请邮件传播服务'
            } else {
              item.isHideBtn3 = true
            }
            item.createTime = item.createTime && item.createTime.indexOf('T') != -1 ? item.createTime.split('T')[0] : item.createTime
            item.finishTime = item.finishTime && item.finishTime.indexOf('T') != -1 ? item.finishTime.split('T')[0] : item.finishTime
            item.planFinishTime = item.planFinishTime && item.planFinishTime.indexOf('T') != -1 ? item.planFinishTime.split('T')[0] : item.planFinishTime
          })
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    handleBtn1Click(item) {
      this.currentItem = item
      if (item.node == 1 || item.node == 2) {
        // 1:待设计 2:待配置 只有查看权限
        let routeData = this.$router.resolve({
          name: 'newApply',
          query: { status: 'watching', id: item.id }
        })
        window.open(routeData.href, '_blank')
      } else if (item.node == 0) {
        // 0:待提交 有编辑和删除权限
        let routeData = this.$router.resolve({
          name: 'newApply',
          query: { status: 'editing', id: item.id }
        })
        window.open(routeData.href, '_blank')
      } else if (item.node == 3) {
        // 3:已完成 预览权限
        this.eldialogVisible = true
        this.getTaskList()
        this.$nextTick(() => {
          this.showURL('qrcodeNewURL')
          this.showURL('qrcodeOldURL')
        })
      }
    },
    handleBtn2Click(item) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let params = { id: item.id }
          DeleteSaleTask(params).then((res) => {
            if (res && res.data && res.data.status === 0) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getMagList()
            } else {
              const { message = '' } = res.data
              this.$message.error(message)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    handleBtn3Click(item) {
      let routeData = this.$router.resolve({
        name: 'newApply',
        query: { status: 'add', id: item.id }
      })
      window.open(routeData.href, '_blank')
    },
    // 复制链接
    copyURL(data) {
      var oInput = document.createElement('input')
      oInput.value = data
      document.body.appendChild(oInput)
      oInput.select() // 选择对象
      document.execCommand('Copy') // 执行浏览器复制命令
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      this.$message({
        message: '复制成功!',
        type: 'success',
        customClass: 'salesMessage'
      })
    },
    //预览显示预览二维码
    showURL(idNode) {
      document.getElementById(idNode).innerHTML = ''
      let qrcode = new QRCode(idNode, {
        width: 120,
        height: 120,
        colorDark: '#000',
        colorLight: '#fff'
      })
      if (idNode === 'qrcodeNewURL') {
        qrcode.makeCode(this.newURL)
      } else if (idNode === 'qrcodeOldURL') {
        qrcode.makeCode(this.oldURL)
      }
    },
    // 获取任务列表数据
    getTaskList() {
      let params = {
        // magId: this.currentItem.magId,
        magId: 9416,
        page: 0,
        size: 10,
        status: 0 + ',' + 1 + ',' + 2 + ',' + 3,
        type: ''
      }
      GetPushTaskList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          if (data && data.content.length > 0) {
            this.getTemplate(data.content[0].id)
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取推送任务模板默认配置
    getTemplate(id) {
      let params = {
        historyId: id,
        isChoice: 1
      }
      GetTemplate(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          let { data } = res.data
          if (data && data.length > 0) {
            data.map((item) => {
              let url =
                window.location.origin +
                '/das-api/vas/push/task/getTemplateHtml5Info?taskId=' +
                item.historyId +
                '&pushHistoryTemplateId=' +
                item.id +
                '&nameSpace=' +
                window.location.hostname +
                (window.location.port ? ':' + window.location.port : '') +
                '/das-api'
              item.jumpUrl = url
            })
          }
          this.$set(this.currentItem, 'emailTemplateStyleArr', data)
        }
      })
    }
  }
}
</script>
<style lang="scss">
.sales {
  .el-dialog__wrapper {
    padding: 0;
  }
  .eldialog-info {
    .eldialog-info-item {
      .el-button {
        margin: 0 10px;
      }
    }
  }
}
.salesMessage.el-message--success {
  z-index: 3026 !important;
}
</style>
<style lang="scss" scoped>
.sales {
  .sales-btns {
    float: left;
    margin-bottom: 20px;
  }
  .eldialog-info {
    .center {
      justify-content: center;
    }
    .eldialog-info-item {
      text-align: left;
      margin: 20px;
      font-size: 16px;
      display: flex;
      align-items: flex-start;
      .eldialog-info-item-title {
        width: 160px;
        text-align: right;
      }
      .code-preview {
        margin: 0 10px 0 40px;
      }
      .eldialog-info-item-arr {
        margin-bottom: 20px;
        font-size: 14px;
        margin-left: 20px;
      }
    }
  }
}
</style>
